import React from 'react';
import './BlogPost.css';

const Article2 = () => {
    return (
        <div className="article-content">
            <h2>Comprendre le diabète : le voyage du glucose dans le corps</h2>
            <p>
                Le diabète est une condition où le corps ne régule pas correctement la glycémie. Voyons ce qui se passe lorsque nous consommons des aliments contenant des glucides :
            </p>
            <ol>
                <li><strong>Ingestion :</strong> Lorsque vous mangez des glucides, ils sont décomposés en glucose (sucre) dans l'estomac et les intestins.</li>
                <li><strong>Absorption :</strong> Le glucose est absorbé dans la circulation sanguine par les parois intestinales.</li>
                <li><strong>Transport :</strong> Le glucose voyage dans le sang vers les cellules du corps, où il est utilisé comme source d'énergie.</li>
                <li><strong>Insuline :</strong> Le pancréas produit de l'insuline, une hormone qui aide les cellules à absorber le glucose.</li>
                <li><strong>Utilisation ou stockage :</strong> Le glucose est utilisé immédiatement pour l'énergie ou stocké dans le foie et les muscles sous forme de glycogène pour une utilisation future.</li>
            </ol>
            <p>
                Chez les personnes atteintes de diabète, ce processus est perturbé. Dans le diabète de type 1, le pancréas ne produit pas d'insuline. Dans le diabète de type 2, le corps devient résistant à l'insuline.
            </p>
            <p>
                Une gestion efficace du diabète implique la surveillance de la glycémie, une alimentation équilibrée, et parfois l'utilisation de médicaments ou d'insuline.
            </p>
        </div>
    );
};

export default Article2;
