import React from 'react';
import './BlogPost.css';

const Article1 = () => {
    return (
        <div className="article-content">
            <h2>Comment fonctionne le système juridique au Québec</h2>
            <p>
                Le système juridique au Québec est basé sur le droit civil, contrairement au reste du Canada qui utilise la common law. 
                Voici un aperçu des principales composantes du système juridique québécois :
            </p>
            <ul>
                <li><strong>Le Code civil du Québec :</strong> Il constitue la base du droit civil et régit les relations entre les individus.</li>
                <li><strong>Les tribunaux :</strong> Ils comprennent les cours municipales, la Cour du Québec, la Cour supérieure et la Cour d'appel.</li>
                <li><strong>Les procédures :</strong> Le processus judiciaire inclut des étapes comme la mise en demeure, le dépôt de la requête, l'audience et le jugement.</li>
            </ul>
            <p>
                Chaque étape du processus est conçue pour être juste et impartiale, assurant que chaque partie a la possibilité de présenter ses arguments. 
                Pour des cas spécifiques, des tribunaux administratifs spécialisés existent également.
            </p>
            <p>
                Le droit québécois évolue constamment pour s'adapter aux changements sociaux et technologiques, garantissant ainsi la justice et l'équité pour tous.
            </p>
        </div>
    );
};

export default Article1;
